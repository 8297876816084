import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export class ServiceForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            trade_hash : "",
            service : "",
            error: false,
            content: this.props.content,
        }
    }

    componentDidMount(){
        if(this.state.content){
            this.fromJson(this.state.content)
        }
    }

    fromJson = (value) => {
        const jsonObj = JSON.parse(value);
        this.setState({
            trade_hash : jsonObj["trade-hash"] === null ? '' : jsonObj["trade-hash"],
            service : ""
        })

    }

    validate = () => {
        if(this.state.service === ''){
            this.setState({error: true});
            return false;
        }
        return true;
    }

    toJson = () => {

        let obj = {
            "trade-hash": this.state.trade_hash,
            "service":  this.state.service,
        }

        const str =  JSON.stringify(obj);
        return str;
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.error && (<Alert severity="error">Required fields are empty!</Alert>)}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        defaultValue = ""
                        label="Service name"
                        variant="outlined"
                        fullWidth
                        value={this.state.service}
                        onChange={(event) => this.setState({service: event.target.value})}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default ServiceForm
