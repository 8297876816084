import React, { Component } from 'react'
import AdminApi from '../../api/AdminApi';
import EventEmitter from '../../components/EventEmitter';
import {Button, ButtonGroup} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Filterless from '../../components/Filterless';
import Table from '../../components/Table';
import BusinessesForm from './BusinessesForm';

class Business extends Component {

    constructor(props){
        super(props);
        this.eventEmitter = new EventEmitter();
        this.state = {
            filterSearchIndex: "resetSearchIndexes",
            paramsIndex: {"business" : true },
            page_size: 10,
            start_key: 0,
            filter: "",
            params: "{}",
            key: null
        }
    }

    componentDidMount = () => {
        this.listenOnEventBus();
    }

    listenOnEventBus() {
        this.eventEmitter.on("filter-table", (payload) => {
            this.setState({
                filter : payload.filter,
                params : payload.params,
                page_size : payload.page_size,
                key : null
            }, this.fetchData);
        });
        this.eventEmitter.on("table-load-more", (payload) => {
            this.setState({
                key : payload.key
            }, this.fetchData);
        });
        this.eventEmitter.on("table-save-item" , async(payload) => {
            await this.storeData(payload.item);
            if(this.state.filter && this.state.params){
                this.fetchData();
            }
        });
        this.eventEmitter.on("table-delete-item" , async(payload) => {
            await this.trashData(payload.item);
            if(this.state.filter && this.state.params){
                this.fetchData();
            }
        });    
    }

    fetchData = async () => {
        const api = new AdminApi();
        const body = await api.getQuery(
            this.state.filter, 
            this.state.params, 
            this.state.page_size, 
            this.state.key);
        
        if(this.state.key){
            this.eventEmitter.emit("table-append-data", {
                data : body.entities,
                key : body.start_key
            })
        } else {
            this.eventEmitter.emit("table-data", {
                data : body.entities,
                key : body.start_key,
            })
        }
        
    }

    storeData = async (item) => { // TODO: for future implementations
        this.setState({key: null})
        const api = new AdminApi();
        await api.storeQuery('storeBusinesses', item);        
    }

    trashData = async (item) => { // TODO: for future implementations
        this.setState({key: null})
        const api = new AdminApi();
        await api.trashQuery('trashBusinesses', item["businesses-id"]);        
    }

    reindexUsers = async () => {
        const api = new AdminApi();
        const body = await api.reindexingQuery(
            this.state.filterSearchIndex, 
            this.state.paramsIndex, 
            this.state.page_size, 
            this.state.start_key);
    }

    render() {
        return (
            <>
                <div className="container">
                    <ButtonGroup variant="outlined">
                        <Button className="reindex-button" onClick={this.reindexUsers}>Reindex Businesses</Button>
                    </ButtonGroup>
                </div>
                <Grid item xs={12}>
                    <div className="title">Business List</div>
                    <Filterless eventemitter={this.eventEmitter} category="businesses" />
                    <Table 
                        eventemitter={this.eventEmitter} 
                        viewable={true} 
                        creatable={false} 
                        editable={false}
                        deletable={false}
                        updateComponent={BusinessesForm}
                        idproperty="business-id"
                        tabId={2}
                    />
                </Grid>
            </>
        )
    }
}

export default Business