import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core';
import { Storage, Auth } from 'aws-amplify';

export class FileUpload extends Component {
    
    static propTypes = {
        onSubmit: PropTypes.func
    }

    constructor(props) {
        super(props);
    
        this.handleChange = this.handleChange.bind(this);
      }
    
      handleChange = (event) => {
        const file = event.target.files[0];
        const url = event.target.value;
        
        this.props.onSubmit(file, url);
      }
    
    
      render() {
        return (
          <form>
            <label>
                <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={this.handleChange}
                />
                <Button type="submit"  color="secondary" variant="contained" component="span">
                    File Upload
                </Button>
            </label>
          </form>
        );
      }
}

export default FileUpload
