import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {FormControl, InputLabel, Select, MenuItem, TextField, Button, ButtonGroup } from '@material-ui/core';
import './Filter.css';

class Filter extends Component {

    constructor(props){
        super(props);
        this.state = {
            filterList: {},
            filter: "",
            params: "",
            pageSize : 10,
            category: props.category,
            viewable: false,
            creatable: false,
            template: false,
            editable: false,
            deletable: false,
        }
    }

    componentDidMount = () => {
        this.fetchFilters();
    }

    fetchFilters = () => {
        fetch("filters.json")
            .then(response => response.json())
            .then(data => {
                this.setState({
                    ...this.state,
                    filterList : data[this.state.category]
                });
            }).catch(err => {
                console.log('Unable to load filters...', err);
            });
    }

    handleFilterChange = (event) => {
        const _filter = event.target.value;

        this.setState({
            ...this.state,
            filter: _filter,
            params: JSON.stringify(this.state.filterList[_filter].params, undefined, 4),
            pageSize: this.state.filterList[_filter]["page-size"],
            viewable: this.state.filterList[_filter]["viewable"],
            creatable: this.state.filterList[_filter]["creatable"],
            template: this.state.filterList[_filter]["template"],
            editable: this.state.filterList[_filter]["editable"],
            deletable: this.state.filterList[_filter]["deletable"],
        }); 

        this.props.eventemitter.emit("filter-selected", { 
            filter: this.state.filterList[_filter]
        });
    }

    handleApplyFilterClick = () => {
        this.props.eventemitter.emit("filter-table", { 
            filter : this.state.filter, 
            params : this.state.params,
            page_size : this.state.pageSize,
            viewable : this.state.viewable,
            creatable : this.state.creatable,
            template : this.state.template,
            editable : this.state.editable,
            deletable : this.state.deletable
        });
    }

    render() {
        return (
            <div>
                <FormControl variant="outlined" className="form-control">
                    <InputLabel id="filter-label">Filter</InputLabel>
                    <Select
                        labelId="filter-label"
                        value={this.state.filter}
                        onChange={this.handleFilterChange}
                        label="Filter"
                    >
                        {
                            this.state.filterList && Object.keys(this.state.filterList).map(key => (
                                <MenuItem key={key} value={key}>{this.state.filterList[key].name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl variant="outlined" className="form-control no-padding">
                    <TextField
                        label="Params"
                        value={this.state.params}
                        variant="outlined"
                        multiline
                        rows={10}
                        onChange={(event) => this.setState({...this.state, params: event.target.value})}
                    />
                </FormControl>
                <ButtonGroup variant="outlined">
                    <Button disabled={!this.state.params} className="filter-button" onClick={this.handleApplyFilterClick}>Apply Filter</Button>
                </ButtonGroup>
            </div>
        );
    }
}

Filter.propTypes = {
    eventemitter: PropTypes.object.isRequired,
}

export default Filter

