import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom';
import {AppBar, Toolbar} from '@material-ui/core'
import Logo from '../images/banner.jpg';
import './Navigation.css'
import Logout from './Logout';


class Navigation extends Component {
    render() {
        return (
            <AppBar position="static" className="app-bar">
                <Toolbar className="tool-bar">
                    <div className="logo">
                        <Link to="/">
                            <img src={Logo} alt="GoTradie Logo" width={200}/>
                        </Link>
                    </div>
                    <div>
                        <nav className="navbar">
                            {/* <NavLink exact={true} activeClassName='is-active' to='/users'>Users</NavLink>
                            <NavLink activeClassName='is-active' to='/queries'>Queries</NavLink>
                            <NavLink activeClassName='is-active' to='/suppliers'>Suppliers</NavLink> */}
                            <NavLink activeClassName='is-active' to='/businesses'>Businesses</NavLink>
                            <NavLink activeClassName='is-active' to='/messages'>Messages</NavLink>
                            <NavLink activeClassName='is-active' to='/seekers'>Seekers</NavLink>
                            <NavLink activeClassName='is-active' to='/users'>Users</NavLink>
                        </nav>
                        <Logout />
                    </div>
                </Toolbar>
            </AppBar>
        )
    }
}

export default Navigation
