import React, { Component } from 'react'
import Navigation from '../components/Navigation'
import { Switch, Route } from 'react-router-dom';
import Users from '../pages/users/Users'
import HomeDashboard from '../pages/dashboards/HomeDashboard'
import Queries from '../pages/queries/Queries'
import Suppliers from '../pages/suppliers/Suppliers'
import Messages from '../pages/messages/Messages'
import businessManage from '../pages/manage/businessManage'
import seekerManage from '../pages/manage/seekerManage'
import userManage from '../pages/manage/userManage';
import Footer from '../components/Footer';
import { ThemeProvider } from '@material-ui/core';
import theme from '../styles/Theme';

class Layout extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Navigation />
                <Switch>
                    <Route exact path='/' component={HomeDashboard } />
                    {/* <Route path='/users' component={Users} /> */}
                    <Route path='/queries' component={Queries} />
                    <Route path='/suppliers' component={Suppliers} />
                    <Route path='/businesses' component={businessManage} />
                    <Route path='/seekers' component={seekerManage} />
                    <Route path='/users' component={userManage} />
                    <Route path='/messages' component={Messages} />
                </Switch>
                <Footer />
            </ThemeProvider>
        )
    }
}

export default Layout
