import React, { Component } from 'react';
import { Grid, TextField, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import GoogleMapReact from 'google-map-react';
import Key from '../../auth/googleAPI';
import { Link } from 'react-router-dom';
import goTradieLogo from '../../images/logo.png'
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import './ServiceArea.css'

const markerStyle = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -100%)"
};

const mapContainerStyle = {
    height: '60vh', 
    width: '50%' 
}

export class ServiceAreaForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            latitude : "",
            longitude : "",
            latitude_delta: "",
            longitude_delta: "",
            area_name: "",
            suburbs: [],
            post_codes: [],
            newSuburbs: "",
            newPostCode: "",
            geohash_values: "",
            image_url: "",
            error: false,
            content: this.props.content,
            zoom_level: 7,
            nELat: "",
            nELng: "",
            sWLat: "",
            sWLng: "",
        }
    }

    componentDidMount(){
        if(this.state.content){
            this.fromJson(this.state.content)
        }
    }

    fromJson = (value) => {
        const jsonObj = JSON.parse(value);
        this.setState({
            latitude : jsonObj["pictol-cordinates"] === null ? '' : jsonObj["pictol-cordinates"]["latitude"],
            longitude : jsonObj["pictol-cordinates"] === null ? '' : jsonObj["pictol-cordinates"]["longitude"],
            latitude_delta : jsonObj["pictol-cordinates"] === null ? '' : jsonObj["pictol-cordinates"]["latitudeDelta"],
            longitude_delta : jsonObj["pictol-cordinates"] === null ? '' : jsonObj["pictol-cordinates"]["longitudeDelta"],
            area_name: jsonObj["area-name"] === null ? '' : jsonObj["area-name"],
            suburbs: jsonObj["suburbs"] === null ? [] : jsonObj["suburbs"],
            post_codes: jsonObj["post-codes"] === null ? [] : jsonObj["post-codes"],
            geohash_values: jsonObj["geohash-values"] === null ? "" : jsonObj["geohash-values"],
            image_url: jsonObj["image-url"] === null ? "" : jsonObj["image-url"],
            zoom_level: jsonObj["zoom-level"] === undefined ? 17 : jsonObj["zoom-level"],
        })

    }

    validate = () => {
        if(this.state.area_name === '' || this.state.latitude === '' || this.state.longitude === '' || this.state.latitude_delta === ''){
            this.setState({error: true});
            return false;
        }
        return true;
    }

    toJson = () => {
        let coordinateObj = {
            "latitude": this.state.latitude,
            "latitudeDelta": this.state.latitude_delta,
            "longitude": this.state.longitude,
            "longitudeDelta": this.state.longitude_delta
        }
        let obj = {
            ...(this.state.content && {"area-id": JSON.parse(this.state.content)["area-id"]}),
            "area-name": this.state.area_name,
            "pictol-cordinates": coordinateObj,
            "suburbs": this.state.suburbs,
            "post-codes": this.state.post_codes,
            "geohash-values": this.state.geohash_values,
            "image-url": this.state.image_url,
            "zoom-level": this.state.zoom_level
        }

        const str =  JSON.stringify(obj);
        return str;
    }

    handleMapClick = ({x, y, lat, lng}) => {
        this.setState({
            latitude : lat,
            longitude : lng
        })
    }

    addDataToArray = (type) => {
        if (type === "Suburb") {
            if (this.state.newSuburbs !== "") {
                let arr = this.state.suburbs
                arr.push(this.state.newSuburbs)
                this.setState({suburbs: arr})
            }
        } else {
            if (this.state.newPostCode !== "") {
                let arr = this.state.post_codes
                arr.push(this.state.newPostCode)
                this.setState({post_codes: arr})
            }
        }
    }

    removeDataFromArray = (type, index) => {
        if (type === "Suburb") {
            let arr = this.state.suburbs
            arr.splice(index, 1)
            this.setState({suburbs: arr})
        } else {
            let arr = this.state.post_codes
            arr.splice(index, 1)
            this.setState({post_codes: arr})
        }
    }

    apiIsLoaded = (map, maps) => {
        map.addListener("zoom_changed", () => {
            this.setState({zoom_level: map.getZoom()})
        });
        map.addListener("bounds_changed", () => {
            this.calculateDeltaValus(map.getBounds())
        });
    };

    calculateDeltaValus = (obj) => {
        this.setState({
            nELat: obj.getNorthEast().lat(),
            nELng: obj.getNorthEast().lng(),
            sWLat: obj.getSouthWest().lat(),
            sWLng: obj.getSouthWest().lng()
        })

        const deltaLat = Math.abs(this.state.nELat - this.state.sWLat)
        const deltaLng = Math.abs(this.state.nELng - this.state.sWLng)
        this.setState({
            latitude_delta: deltaLat,
            longitude_delta: deltaLng
        })
    }

    render() {
        let center = {
            lat: this.state.latitude,
            lng: this.state.longitude
        }
        if (this.state.latitude === '' || this.state.longitude === '') { // if new service area
            center.lat = -25.2744
            center.lng = 133.7751
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.error && (<Alert severity="error">One or more required fields are empty!</Alert>)}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Area Name"
                        variant="outlined"
                        fullWidth
                        value={this.state.area_name}
                        onChange={(event) => this.setState({area_name: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div style={mapContainerStyle}>
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: Key(),
                                language: 'en',
                                libraries: "drawing",
                            }}
                            defaultCenter={center}
                            defaultZoom={this.state.zoom_level}
                            onClick={ this.handleMapClick}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
                            >
                            <Link lat={this.state.latitude} lng={this.state.longitude}>
                                <img style={markerStyle} src={goTradieLogo} alt="pin" />
                            </Link>
                        </GoogleMapReact>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Latitude"
                        variant="outlined"
                        fullWidth
                        value={this.state.latitude}
                        onChange={(event) => this.setState({latitude: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Longitude"
                        variant="outlined"
                        fullWidth
                        value={this.state.longitude}
                        onChange={(event) => this.setState({longitude: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Latitude Delta"
                        variant="outlined"
                        fullWidth
                        value={this.state.latitude_delta}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Longitude Delta"
                        variant="outlined"
                        fullWidth
                        value={this.state.longitude_delta}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <div class="item-add-row">
                        <TextField
                            label="New Suburb"
                            variant="outlined"
                            fullWidth
                            value={this.state.newSuburbs}
                            onChange={(event) => this.setState({newSuburbs: event.target.value})}
                        />
                        <IconButton onClick={() => this.addDataToArray("Suburb")} title="Add New">
                            <AddCircleIcon />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div class="item-add-row">
                        <TextField
                            label="New Post Code"
                            variant="outlined"
                            fullWidth
                            value={this.state.newPostCode}
                            onChange={(event) => this.setState({newPostCode: event.target.value})}
                        />
                        <IconButton onClick={() => this.addDataToArray("PostCode")} title="Add New">
                            <AddCircleIcon />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div class="item-delete-row">
                        {this.state.suburbs !== [] && this.state.suburbs.map((el, index) => (
                            <div class="item-row">
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={el}
                                    disabled
                                />
                                <IconButton onClick={() => this.removeDataFromArray("Suburb", index)} title="Delete">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        ))}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div class="item-delete-row">
                        {this.state.post_codes !== [] && this.state.post_codes.map((el, index) => (
                            <div class="item-row">
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={el}
                                    disabled
                                />
                                <IconButton onClick={() => this.removeDataFromArray("PostCode", index)} title="Delete">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        ))}
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default ServiceAreaForm
