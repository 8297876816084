import { Auth, API } from 'aws-amplify';
import axios from 'axios';

class ImagesApi {
    apiName = 'adminApi';
    path = '/images';
    initState = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }

    getImageUploadUrl = (image_type) => {
        return new Promise(async (resolve, reject) => {
            let user = await Auth.currentUserInfo();
            let body = {
                "user-id" : user.attributes.sub,
                "image-type" : image_type
            }
            try {
                const response = await API.post(
                    this.apiName, 
                    this.path, 
                    {
                        ...this.initState,
                        body
                    });

                resolve(response);
            } catch (error) {
                reject(error)
            }
        });
    }

    uploadImageToURL = (url, data) => {
        return axios.request({
            method: "post", 
            url:url, 
            data:data
        });
    }

    uploadImageToS3Bucket = (image_type, file) => {
        return new Promise(async (resolve, reject) => {
            
            const response = await this.getImageUploadUrl(image_type);

            let imageMeta = response["image-metadata"];
            const formdata = new FormData();
            formdata.append("key",imageMeta.key);
            formdata.append("AWSAccessKeyId",imageMeta.AWSAccessKeyId);
            formdata.append("x-amz-security-token",imageMeta["x-amz-security-token"]);
            formdata.append("policy",imageMeta.policy);
            formdata.append("signature",imageMeta.signature);
            formdata.append("file", file);

            const uploadResponse = await this.uploadImageToURL(response.presigned_post_url, formdata);
            if(uploadResponse.status >= 200 && uploadResponse.status < 300){
                resolve(imageMeta.key);
            }else{
                reject('');
            }
        })
    }
}

export default ImagesApi
