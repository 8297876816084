import React, { Component } from 'react';
import { render } from 'react-dom'
import { Grid } from '@material-ui/core';
//import { Col} from '@material-ui/core';
import Highcharts, { column2d } from 'highcharts'
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import HighchartsReact from 'highcharts-react-official'
//import PureChart from 'react-native-pure-chart'
//import ColumnChart from './components/column-chart'
//import { TextField, InputAdornment, Button, IconButton, Dialog, DialogContent, DialogActions, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
//import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
//import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import ArrowKeysReact from '@material-ui/core';
//import { Container, Row, Col } from 'react-grid-system';
//import { Icon } from 'semantic-ui-react'
class HomeDashboard extends Component {
  
    render() {
        const options = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'New Users per Month'
            },
            xAxis: {
                categories: [
                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',  'Sep', 'Oct', 'Nov', 'Dec'
                ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Rainfall (mm)'
                }
            },   
            series: [
                {name: 'Total',
                data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]},
        {name: 'Tradies',
        data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]},
        {name: 'General',
        data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2]},
        {name: 'Users',
        data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1]}
        ]
    
        };
        
        const options2={
            chart: {
                type: 'column'
            },
            title: {
                text: 'Daily Active Users'
            },
            xAxis: {
                categories: [
                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',  'Sep', 'Oct', 'Nov', 'Dec'
                ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Rainfall (mm)'
                }
            },   
            series: [
                {name: 'Total',
                data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]},
        {name: 'Tradies',
        data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]},
        {name: 'General',
        data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2]},
        {name: 'Users',
        data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1]}
        ]
    
        };
        
        return (
            <div className="container">
                <div className="grid-con">
  <div className=" griditem">
  <p className="mt-3 mb-0 text-muted text-sm ">
                        <span className="h2 ">
                        The Number of Total Users
                        <br></br>
                  
                        </span>
                        <span className="h2 text-uppercase text-muted">

                        </span>
                        <b>180</b>
                        <i className="arrow-up"></i>
                      </p>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                        <i className="arrow up"></i>{" "," "}3
                        </span>{" "}
                      
                      </p>
  </div>
  <div className=" griditem">
  <p className="mt-3 mb-0 text-muted text-sm ">
                        <span className="h2 ">
                        The Number of General Users
                        <br></br>
                  
                        </span>
                        <span className="h2 text-uppercase text-muted">

                        </span>
                        <b>50</b>
                        <i className="arrow-up"></i>
                      </p>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                        <i className="arrow up"></i>{" "," "}5
                        </span>{" "}
                        
                      </p>
  </div>
  <div className=" griditem">
  <p className="mt-3 mb-0 text-muted text-sm ">
                        <span className="h2 ">
                       Number of Tradies
                        <br></br>
                        </span>
                        <span className="h2 text-uppercase text-muted">

                        </span>
                        <b>135</b>
                        <i className="arrow-up"></i>
                      </p>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                        <i className="arrow up"></i> 2
                        </span>{" "}
                       
                      </p>
  </div>
  </div>
               

                <Grid container>
                    <Grid container item xs={12} sm={6} >
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={6}>
                    <HighchartsReact
                            highcharts={Highcharts}
                            options={options2}
                        />
                    </Grid>
                   
                    {/* <Grid container item xs={12} spacing={3}>
                    <FormRow />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <FormRow />
                </Grid> */}
                </Grid>
            </div>
            
        )
    }
}

export default HomeDashboard