import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export class BusinessesForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            business_id: "",
            business_name : "",
            handle : "",
            owners: {},
            phone_number: "",
            address: "",
            trades: [],
            content: this.props.content,
            error: false
        }
    }

    componentDidMount(){
        if(this.state.content){
            this.fromJson(this.state.content)
        }
    }

    fromJson = (value) => {
        const jsonObj = JSON.parse(value);
        this.setState({
            business_id : jsonObj["business-id"] ? jsonObj["business-id"] : '',
            business_name : jsonObj["business-name"] ? jsonObj["business-name"] : '',
            handle : jsonObj["handle"] ? jsonObj["handle"] : '',
            phone_number: jsonObj["phone-number"] ? jsonObj["phone-number"] : '',
            address: jsonObj["address"] ? jsonObj["address"]["address"] : '',
            trades: jsonObj["trades"] ? jsonObj["trades"] : [],
        })

    }

    validate = () => { // // TODO: will used with business edit
        if(this.state.business_name === ''){
            this.setState({error: true});
            return false;
        }
        return true;
    }

    toJson = () => { // TODO: will used with business edit

        let obj = {
            "business-id": this.state.business_id,
            "business-name":  this.state.business_name,
            "handle":  this.state.handle,
            "phone-number": this.state.phone_number,
            "trades": this.state.trades,
        }

        const str =  JSON.stringify(obj);
        return str;
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.error && (<Alert severity="error">One or more required fields are empty!</Alert>)}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Business Name"
                        variant="outlined"
                        fullWidth
                        value={this.state.business_name}
                        onChange={(event) => this.setState({business_name: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Business Handle"
                        variant="outlined"
                        fullWidth
                        value={this.state.handle}
                        onChange={(event) => this.setState({handle: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Business ID"
                        variant="outlined"
                        fullWidth
                        value={this.state.business_id}
                        onChange={(event) => this.setState({business_id: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        value={this.state.phone_number}
                        onChange={(event) => this.setState({handle: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        label="Address"
                        variant="outlined"
                        fullWidth
                        value={this.state.address}
                        onChange={(event) => this.setState({handle: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    {this.state.trades.length > 0 && <p>Business Trades</p>}
                    <div class="item-delete-row">
                        {this.state.trades.length > 0 && this.state.trades.map((el, index) => (
                            <div class="item-row">
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={el}
                                    disabled
                                />
                            </div>
                        ))}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <a href={`https://profiles${process.env.NODE_ENV === 'development' ? `.dev.` : "."}gotradie.com.au/${this.state.handle}`} rel="noopener noreferrer" target="_blank">View Business Profile</a>
                </Grid>
            </Grid>
        )
    }
}

export default BusinessesForm
