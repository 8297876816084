import React from 'react';
import Authorization from '../auth/Authorization';
import { BrowserRouter as Router} from 'react-router-dom';
import Layout from './Layout';

function App() {
  
  return (
    <Router>
      <Authorization>
        <Layout />   
      </Authorization>
    </Router>
  );
}

export default App;

