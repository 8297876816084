import React, { Component } from 'react';
import { Button,  FormControl, Grid, IconButton,  InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import FileUpload from '../../components/FileUpload';
import TradesApi from '../../api/TradesApi';
import Alert from '@material-ui/lab/Alert';
import ImagesApi from '../../api/ImagesApi';
import AdminApi from '../../api/AdminApi';

export class SupplierForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            supplier_id : "",
            supplier_name : "",
            description : "",
            website: "",
            profile_pic_url: "",
            profile_pic: null,
            trades_list: [],
            trades: [],
            stores_locations: [],
            address : {
                address_name : "",
                address : "",
                latitude : 0.0,
                longitude: 0.0,
            },
            store_phone_contacts: [],
            contact : {
                store_name : "",
                phone : ""
            },
            store_email_contacts: [],
            email_contact: {
                store_name: "",
                email : ""

            },
            store_opening_hours: [],
            open_hours : {
                store_name: "",
                open_time: [],
                days: "",
                time: ""
            },
            prof_pic: "",
            content: this.props.content,
            error: false
        }
    }

    componentDidMount(){
        this.fetchTrades();
        if(this.state.content){
            this.fromJson(this.state.content)
        }
    }

    
    fetchTrades = async () => {
        const api = new TradesApi();
        const result = await api.getTrades();
        
        this.setState({
            trades_list: result.trades
        })        
    }

    fromJson = (value) => {
        const jsonObj = JSON.parse(value);
        const stores_locations = jsonObj["stores-locations"] === null ? [] : jsonObj["stores-locations"].map(item => {
            return {
                address_name : item["address-name"],
                address : item["address"],
                latitude : item["cordinates"]["latitude"],
                longitude : item["cordinates"]["longitude"]
            };
        });
        const store_phone_contacts = jsonObj["store-phone-contacts"] === null ? [] : jsonObj["store-phone-contacts"].map(item => {
            return {
                store_name : item["store-name"],
                phone : item["phone"]
            }
        });
        const store_email_contacts = jsonObj["store-email-contacts"] === null ? [] : jsonObj["store-email-contacts"].map(item => {
            return {
                store_name : item["store-name"],
                email : item["email"]
            }
        });
        const store_opening_hours = jsonObj["store-opening-hours"] === null ? [] : jsonObj["store-opening-hours"].map(item => {
            return {
                store_name : item["store-name"],
                open_time : item["open-times"]
            }
        })

        this.setState({
            supplier_id : jsonObj["supplier-id"] === null ? '' : jsonObj["supplier-id"],
            supplier_name : jsonObj["supplier-name"] === null ? '' : jsonObj["supplier-name"],
            description : jsonObj["description"] === null ? '' : jsonObj["description"],
            stores_locations,
            store_phone_contacts,
            store_email_contacts,
            store_opening_hours,
            website : jsonObj["website"] === null ? '' : jsonObj["website"],
            trades : jsonObj["trades"],
            prof_pic: jsonObj["profile-pic-obj"],
        })

    }

    validate = () => {
        if(this.state.supplier_name === ''){
            this.setState({error: true});
            return false;
        }
        return true;
    }

    toJson = () => {
        const store_locations = this.state.stores_locations.map(location => {
            return {
                "address-name": location.address_name,
                "address": location.address,
                "cordinates": {
                    "latitude": location.latitude,
                    "longitude": location.longitude
                }
            };
        });
        const store_phone_contacts = this.state.store_phone_contacts.map(contact => {
            return {
                "store-name" : contact.store_name,
                "phone" : contact.phone
            };
        });
        const store_email_contacts = this.state.store_email_contacts.map(contact => {
            return {
                "store-name" : contact.store_name,
                "email" : contact.email
            };
        });
        const store_opening_hours = this.state.store_opening_hours.map(item => {
            const open_times = item.open_time.map(innerItem => {
                return {
                    "days": innerItem.days,
                    "time": innerItem.time
                }
            })
            return {
                "store-name" : item.store_name,
                "open-times" : open_times
            }
        })

        let obj = {
            "supplier-id": this.state.supplier_id,
            "supplier-name":  this.state.supplier_name,
            "description":  this.state.description,
            "stores-locations": store_locations,
            "store-phone-contacts": store_phone_contacts,
            "store-email-contacts": store_email_contacts,
            "store-opening-hours": store_opening_hours,
            "website": this.state.website,
            "profile-pic-obj": this.state.prof_pic,
            "trades": this.state.trades
        }

        const str =  JSON.stringify(obj);
        return str;
    }

    onImageSubmit = async (file, url) => {
        const api = new ImagesApi();
        const file_key = await api.uploadImageToS3Bucket('supplier', file);

        if(file_key !== ''){
            this.setState({prof_pic: file_key})
        }
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.error && (<Alert severity="error">One or more required fields are empty!</Alert>)}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Supplier Name"
                        variant="outlined"
                        fullWidth
                        value={this.state.supplier_name}
                        onChange={(event) => this.setState({supplier_name: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Website"
                        variant="outlined"
                        fullWidth
                        value={this.state.website}
                        onChange={(event) => this.setState({website: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={this.state.description}
                        onChange={(event) => this.setState({description: event.target.value})}
                    />
                </Grid>
                {this._renderSectionHeader('Profile Picture')}
                <Grid item xs={12}>
                    <Typography variant="body2">{this.state.prof_pic}</Typography>
                    <FileUpload onSubmit={(file, url) => this.onImageSubmit(file, url)} />
                </Grid>
                
                {this._renderSectionHeader('Locations')}
                {this.state.stores_locations.map( (location, index) => 
                    <Grid item xs={12} key={`location-${index}`} className="no-verticle-wrappers">
                        <Typography variant="body2">
                            {`${location.address_name}: ${location.address} (lon:${location.longitude}, lat: ${location.latitude})`}
                            <IconButton onClick={() => {
                                let locations = this.state.stores_locations;
                                locations.splice(index, 1);
                                this.setState({stores_locations: locations});
                            }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                    </Grid>
                )}
                {this._renderAddress()}
                
                {this._renderSectionHeader('Phone Numbers')}
                {this.state.store_phone_contacts.map( (contact, index) => 
                    <Grid item xs={12} key={`contact-${index}`} className="no-verticle-wrappers">
                        <Typography variant="body2">
                            {`${contact.store_name}: ${contact.phone}`}
                            <IconButton onClick={() => {
                                let phone_contacts = this.state.store_phone_contacts;
                                phone_contacts.splice(index, 1);
                                this.setState({store_phone_contacts: phone_contacts});
                            }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                    </Grid>
                )}
                {this._renderPhoneContacts()}

                {this._renderSectionHeader('Email Addresses')}
                {this.state.store_email_contacts.map( (email_contact, index) => 
                    <Grid item xs={12} key={`email-contact-${index}`} className="no-verticle-wrappers">
                        <Typography variant="body2">
                            {`${email_contact.store_name}: ${email_contact.email}`}
                            <IconButton onClick={() => {
                                let email_contacts = this.state.store_email_contacts;
                                email_contacts.splice(index, 1);
                                this.setState({store_email_contacts: email_contacts});
                            }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                    </Grid>
                )}
                {this._renderEmailContacts()}
                
                {this._renderSectionHeader('Open Hours')}
                {this.state.store_opening_hours.map( (open_hours, index) => 
                    <Grid item xs={12} key={`email-contact-${index}`} className="no-verticle-wrappers">
                        <Typography variant="body2">
                            {`${open_hours.store_name} => ${(open_hours.open_time.map((open_time, index) => {
                                return open_time.days + ': ' + open_time.time;
                            }).join(', '))}`}
                            <IconButton onClick={() => {
                                let open_hours = this.state.store_opening_hours;
                                open_hours.splice(index, 1);
                                this.setState({store_opening_hours: open_hours});
                            }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Typography>
                    </Grid>
                )}
                {this._renderOpeningHours()}
                
                {this._renderSectionHeader('Trades')}
                {this._renderTrades()}
            </Grid>
        )
    }

    _renderSectionHeader = (title) => {
        return (<Grid item xs={12}><Typography variant="h6"> {title}: </Typography></Grid>);
    }

    _renderAddress = () => {
        return (<React.Fragment>
            <Grid item xs={5}>
                <TextField
                    label="Address Name"
                    variant="outlined"
                    fullWidth
                    value={this.state.address.address_name}
                    onChange={(event) => this.setState({address : {...this.state.address, address_name: event.target.value}})}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Latitude"
                    variant="outlined"
                    type="number"
                    fullWidth
                    value={this.state.address.latitude}
                    onChange={(event) => this.setState({address : {...this.state.address, latitude: event.target.value}})}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Longitude"
                    variant="outlined"
                    type="number"
                    fullWidth
                    value={this.state.address.longitude}
                    onChange={(event) => this.setState({address : {...this.state.address, longitude: event.target.value}})}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={this.state.address.address}
                    onChange={(event) => this.setState({address : {...this.state.address, address: event.target.value}})}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" size="medium" color="default"
                    onClick={() => {
                        const locations = [...this.state.stores_locations, this.state.address];
                        this.setState({
                            stores_locations: locations,
                            address : {
                                address_name : "",
                                address : "",
                                latitude : 0.0,
                                longitude: 0.0,
                            }
                        })
                    }}
                >Add Location</Button>
            </Grid>
        </React.Fragment>);
    }

    _renderPhoneContacts = () => {
        return (<React.Fragment>
            <Grid item xs={5}>
                <TextField
                    label="Store Name"
                    variant="outlined"
                    fullWidth
                    value={this.state.contact.store_name}
                    onChange={(event) => this.setState({contact : {...this.state.contact, store_name: event.target.value}})}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    type="tel"
                    value={this.state.contact.phone}
                    onChange={(event) => this.setState({contact : {...this.state.contact, phone: event.target.value}})}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" size="medium" color="default"
                    onClick={() => {
                        const contacts = [...this.state.store_phone_contacts, this.state.contact];
                        this.setState({
                            store_phone_contacts: contacts,
                            contact : {
                                store_name : "",
                                phone : "",
                            }
                        })
                    }}
                >Add Contact</Button>
            </Grid>
        </React.Fragment>);
    }

    _renderEmailContacts = () => {
        return (<React.Fragment>
            <Grid item xs={5}>
                <TextField
                    label="Store Name"
                    variant="outlined"
                    fullWidth
                    value={this.state.email_contact.store_name}
                    onChange={(event) => this.setState({email_contact : {...this.state.email_contact, store_name: event.target.value}})}
                />
            </Grid>
            <Grid item xs={7}>
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    value={this.state.email_contact.email}
                    onChange={(event) => this.setState({email_contact : {...this.state.email_contact, email: event.target.value}})}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" size="medium" color="default"
                    onClick={() => {
                        const email_contacts = [...this.state.store_email_contacts, this.state.email_contact];
                        this.setState({
                            store_email_contacts: email_contacts,
                            email_contact : {
                                store_name : "",
                                email : "",
                            }
                        })
                    }}
                >Add Email</Button>
            </Grid>
        </React.Fragment>);
    }

    _renderOpeningHours = () => {
        return (<React.Fragment>
            <Grid item xs={5}>
                <TextField
                    label="Store Name"
                    variant="outlined"
                    fullWidth
                    value={this.state.open_hours.store_name}
                    onChange={(event) => this.setState({open_hours : {...this.state.open_hours, store_name: event.target.value}})}
                />
            </Grid>
            <Grid item xs={7}>
            </Grid>
            {this.state.open_hours.open_time.map( (open_time, index) => 
                <Grid item xs={12} key={`open-time-${this.state.store_opening_hours.length + 1}-${index}`} className="no-verticle-wrappers">
                    <Typography variant="body2">
                        {`${open_time.days} : ${open_time.time}`}
                        <IconButton onClick={() => {
                            let open_times = this.state.open_hours.open_time;
                            open_times.splice(index, 1);
                            this.setState({open_hours : {...this.state.open_hours, open_time: open_times}});
                        }}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                </Grid>
            )}
            <Grid item xs={3}>
                <TextField
                    label="Day(s)"
                    variant="outlined"
                    fullWidth
                    placeholder="Mon-Fri / Saturday / Sunday"
                    value={this.state.open_hours.days}
                    onChange={(event) => this.setState({open_hours : {...this.state.open_hours, days: event.target.value}})}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Open Time"
                    variant="outlined"
                    fullWidth
                    placeholder="10am"
                    value={this.state.open_hours.otime}
                    onChange={(event) => this.setState({open_hours : {...this.state.open_hours, otime: event.target.value}})}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Close Time"
                    variant="outlined"
                    placeholder="7pm"
                    value={this.state.open_hours.ctime}
                    onChange={(event) => this.setState({open_hours : {...this.state.open_hours, ctime: event.target.value}})}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={() => {
                    let open_times = this.state.open_hours.open_time;
                    open_times.push({days: this.state.open_hours.days, time: `${this.state.open_hours.otime}-${this.state.open_hours.ctime}` })
                    this.setState({open_hours: {...this.state.open_hours, open_time: open_times, days: "", otime: "", ctime: ""}});
                }}>
                    <AddIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" size="medium" color="default"
                    onClick={() => {
                        const opening_hours = [...this.state.store_opening_hours, { store_name: this.state.open_hours.store_name, open_time: this.state.open_hours.open_time}];
                        this.setState({
                            store_opening_hours: opening_hours,
                            open_hours : {
                                store_name : "",
                                open_time: [],
                                days: "",
                                time: ""
                            }
                        })
                    }}
                >Add Store Opening Hours</Button>
            </Grid>
        </React.Fragment>);
    }

    _renderTrades = () => {
        return (<Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-label">Trades</InputLabel>
                <Select
                    labelId="select-label"
                    value={this.state.trades}
                    onChange={event => this.setState({trades: event.target.value})}
                    label="Trades"
                    multiple
                >
                    {this.state.trades_list.map((trade, index) => (
                    <MenuItem key={`trade-${index}`} value={trade["trade-name"].toLowerCase()}>
                        {trade["trade-name"]}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
          </Grid>);
    }
}

export default SupplierForm
