import React, { Component } from 'react'
import Amplify, {Auth} from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import HomeBanner from '../components/HomeBanner';
import Footer from '../components/Footer';
import './Authorization.css'

class Authorization extends Component {
    
    constructor(props) {
        super(props)
        this.apiBaseUrl = "";

        fetch('config.json').then(response => response.json()).then(config =>{
            const awsconfig = {
                Auth: {
                  // Amazon Cognito Identity Pool ID
                  identityPoolId: config.CognitoIdentityPoolId,

                  // Amazon Cognito Region
                  region: config.CognitoPoolID.length > 0 ? config.CognitoPoolID.split("_")[0] : '',
              
                  // Amazon Cognito User Pool ID
                  userPoolId: config.CognitoPoolID,
              
                  // Amazon Cognito Web Client ID (26-char alphanumeric string)
                  userPoolWebClientId: config.CognitoClientId
                },
                API: {
                    endpoints: [
                        {
                            name: "adminApi",
                            endpoint: config.ApiGatewayUrl,
                            custom_header: async () => { 
                                return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                            }
                        }
                    ]
                },
                Storage: {
                    bucket: 'imagesbucket-master', //REQUIRED -  Amazon S3 bucket
                    region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
                }
              };
            Amplify.configure(awsconfig);
            this.apiBaseUrl = config.ApiGatewayUrl;
        }) 
        

        this.state = {
            hasAuthenticated : false,
            authState : null
        }
    }

    handleLoginState = (event) => {
        this.setState({
            hasAuthenticated: event === "signedin",
            authState: event
        })
    }

    getApiBaseUrl = () => {
        return this.apiBaseUrl;
    }

    render() {
        return (
            <div className="wrapper">
                
                {
                    !this.state.hasAuthenticated && (<HomeBanner />)
                }
                <AmplifyAuthenticator handleAuthStateChange={this.handleLoginState}>
                    <AmplifySignIn hideSignUp={true} slot="sign-in"></AmplifySignIn>
                    {this.props.children}
                </AmplifyAuthenticator>
                {
                    !this.state.hasAuthenticated && (<Footer />)
                }
            </div>
            
        )
    }
}

export default Authorization
