import React from 'react'
import { Auth } from 'aws-amplify';
import './Logout.css';

export default function Logout() {
    const handleLogoutClick = async (event) => {
        try {
            await Auth.signOut()
        }
        catch(e) {
            console.error("Unable to logout from Cognito", e);
        }
    }

    return (
            <button className="logout" onClick={handleLogoutClick}>SIGN OUT</button>
    )
}
