import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {FormControl, InputLabel, Select, MenuItem, TextField, Button, ButtonGroup } from '@material-ui/core';
import './Filter.css';

class Filterless extends Component {

    constructor(props){
        super(props);
        this.state = {
            filterList: {},
            filter: "",
            params: "",
            pageSize : 10,
            category: props.category,
            viewable: false,
            creatable: false,
            template: false,
            editable: false,
            deletable: false,
        }
    }

    componentDidMount = () => {
        this.fetchFilters();
    }

    fetchFilters = () => {
        fetch("filters.json")
            .then(response => response.json())
            .then(data => {
                this.setState({
                    ...this.state,
                    filterList : data[this.state.category]
                }, () => this.applyFilter());
            }).catch(err => {
                console.log('Unable to load filters...', err);
            });
    }

    applyFilter = () => {
        const _filter = Object.keys(this.state.filterList)[0];

        this.setState({
            ...this.state,
            filter: _filter,
            params: JSON.stringify(this.state.filterList[_filter].params, undefined, 4),
            pageSize: this.state.filterList[_filter]["page-size"],
            viewable: this.state.filterList[_filter]["viewable"],
            creatable: this.state.filterList[_filter]["creatable"],
            template: this.state.filterList[_filter]["template"],
            editable: this.state.filterList[_filter]["editable"],
            deletable: this.state.filterList[_filter]["deletable"],
        }, () => {
            this.emitEvents();
        }); 

        
    }

    emitEvents = () => {
        this.props.eventemitter.emit("filter-selected", { 
            filter: this.state.filterList[this.state.filter]
        });

        this.props.eventemitter.emit("filter-table", { 
            filter : this.state.filter, 
            params : this.state.params,
            page_size : this.state.pageSize,
            viewable : this.state.viewable,
            creatable : this.state.creatable,
            template : this.state.template,
            editable : this.state.editable,
            deletable : this.state.deletable
        });
    }

    render() {
        return (<div></div>);
    }
}

Filterless.propTypes = {
    eventemitter: PropTypes.object.isRequired,
}

export default Filterless

