import React, { Component } from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import TradeList from '../trade-list/TradeList';
import Suggestions from '../suggestions/Suggestions';
import Services from '../services/Services'
import Business from '../business/Business';
import ServiceArea from '../service-area/ServiceArea';

export class businessManage extends Component {
    
    constructor(props){
        super(props);
        this.state={
            selectedTabIndex: 0
        }
    }

    handleTabSelect = (event, newValue) => {
        this.setState({selectedTabIndex: newValue});
    }

    render() {
        return (
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="tabs">
                            <Tabs
                                value={this.state.selectedTabIndex}
                                onChange={this.handleTabSelect}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab label="Trade List" />
                                <Tab label="Suggestions"/>
                                <Tab label="Services"/>
                                <Tab label="Businesses"/>
                                <Tab label="Service Areas"/>
                            </Tabs>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div hidden={this.state.selectedTabIndex !== 0}>
                            <TradeList />
                        </div>
                        <div hidden={this.state.selectedTabIndex !== 1}>
                            <Suggestions />
                        </div>
                        <div hidden={this.state.selectedTabIndex !== 2}>
                            <Services />
                        </div>
                        <div hidden={this.state.selectedTabIndex !== 3}>
                            <Business />
                        </div>
                        <div hidden={this.state.selectedTabIndex !== 4}>
                            <ServiceArea />
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default businessManage
