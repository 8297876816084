import React, { Component } from 'react'
import Banner from '../images/banner.jpg'
import './HomeBanner.css'

export class HomeBanner extends Component {
    
    render() {
        return (
            <div className="banner-wrapper">
                <img src={Banner} alt="GoTradie" height={100}/>
            </div>
        )
    }
}

export default HomeBanner
