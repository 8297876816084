import React, { Component } from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import Seeker from '../seeker/Seeker';

export class seekerManage extends Component {
    
    constructor(props){
        super(props);
        this.state={
            selectedTabIndex: 0
        }
    }

    handleTabSelect = (event, newValue) => {
        this.setState({selectedTabIndex: newValue});
    }

    render() {
        return (
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="tabs">
                            <Tabs
                                value={this.state.selectedTabIndex}
                                onChange={this.handleTabSelect}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                              <Tab label="Seeker"/>
                            </Tabs>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div hidden={this.state.selectedTabIndex !== 0}>
                            <Seeker />
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default seekerManage
