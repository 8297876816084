import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import Filterless from '../../components/Filterless';
import Table from '../../components/Table';
import AdminApi from '../../api/AdminApi';
import EventEmitter from '../../components/EventEmitter';
import SuggestionForm from './SuggestionsForm';

class Suggestions extends Component {
    constructor(props){
        super(props);
        this.eventEmitter = new EventEmitter();
        this.state = {
            filter: "",
            params: "{}",
            page_size: 10,
            key: null
        }
    }

    componentDidMount = () => {
        this.listenOnEventBus();
    }

    listenOnEventBus() {
        this.eventEmitter.on("filter-table", (payload) => {
            this.setState({
                filter : payload.filter,
                params : payload.params,
                page_size : payload.page_size,
                key : null
            }, this.fetchData);
        });
        this.eventEmitter.on("table-load-more", (payload) => {
            this.setState({
                key : payload.key
            }, this.fetchData);
        });
        this.eventEmitter.on("table-save-item" , async(payload) => {
            await this.storeData(payload.item);
            if(this.state.filter && this.state.params){
                this.fetchData();
            }
        });         
        this.eventEmitter.on("table-delete-item" , async(payload) => {
            await this.trashData(payload.item);
            if(this.state.filter && this.state.params){
                this.fetchData();
            }
        });
    }

    fetchData = async () => {
        const api = new AdminApi();
        const body = await api.getQuery(
            this.state.filter, 
            this.state.params, 
            this.state.page_size, 
            this.state.key);
        
        if(this.state.key){
            this.eventEmitter.emit("table-append-data", {
                data : body.entities,
                key : body.start_key
            })
        } else {
            this.eventEmitter.emit("table-data", {
                data : body.entities,
                key : body.start_key,
            })
        }
        
    }

    storeData = async (item) => {
        this.setState({key: null})
        const api = new AdminApi();
        await api.storeQuery('storeSuggestion', item);           
    }

    trashData = async (item) => {
        this.setState({key: null})
        const api = new AdminApi();
        await api.trashQuery('trashSuggestion', item["suggestion-id"]);        
    }
   
    render() {
        return (
            <Grid item xs={12}>
                <div className="title">Suggestions</div>
                <Filterless eventemitter={this.eventEmitter} category="suggestions" />
                <Table 
                    eventemitter={this.eventEmitter} 
                    viewable={true} 
                    creatable={true} 
                    editable={false}
                    deletable={true}
                    updateComponent={SuggestionForm}
                    idproperty="suggestion-id"
                    tabId={1}
                />
            </Grid>
        )
    }
}

export default Suggestions
