import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export class SeekerForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            purpose_id : "",
            purpose : "",
            category : "Default",
            content: this.props.content,
            error: false
        }
    }

    componentDidMount(){
        if(this.state.content){
            this.fromJson(this.state.content)
        }
    }

    fromJson = (value) => {
        const jsonObj = JSON.parse(value);
        this.setState({
            purpose_id : jsonObj["purpose-id"] === null ? '' : jsonObj["purpose-id"],
            purpose : jsonObj["purpose"] === null ? '' : jsonObj["purpose"],
            category : "Default"
        })

    }

    validate = () => {
        if(this.state.purpose === ''){
            this.setState({error: true});
            return false;
        }
        return true;
    }

    toJson = () => {

        let obj = {
            "purpose-id": this.state.purpose_id,
            "purpose":  this.state.purpose,
            "category":  this.state.category
        }

        const str =  JSON.stringify(obj);
        return str;
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.error && (<Alert severity="error">One or more required fields are empty!</Alert>)}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Purpose"
                        variant="outlined"
                        fullWidth
                        value={this.state.purpose}
                        onChange={(event) => this.setState({purpose: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        required
                        defaultValue = "Default"
                        label="Category"
                        variant="outlined"
                        fullWidth
                        value={this.state.category}
                        onChange={(event) => this.setState({category: event.target.value})}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default SeekerForm
