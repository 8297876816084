import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export class BlacklistedForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            blacklisted_user_id : "",
            phone_number : "",
            content: this.props.content,
            error: false
        }
    }

    componentDidMount(){
        if(this.state.content){
            this.fromJson(this.state.content)
        }
    }

    fromJson = (value) => {
        const jsonObj = JSON.parse(value);
        this.setState({
            blacklisted_user_id : jsonObj["blacklisted-user-id"] === null ? '' : jsonObj["blacklisted-user-id"],
            phone_number : jsonObj["phone-number"] === null ? '' : jsonObj["phone-number"],
        })

    }

    validate = () => {
        if(this.state.phone_number === ''){
            this.setState({error: true});
            return false;
        }
        return true;
    }

    toJson = () => {

        let obj = {
            "purpose-id": this.state.blacklisted_user_id,
            "phone-number":  this.state.phone_number,
        }

        const str =  JSON.stringify(obj);
        return str;
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.error && (<Alert severity="error">One or more required fields are empty!</Alert>)}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Mobile number"
                        variant="outlined"
                        fullWidth
                        value={this.state.phone_number}
                        onChange={(event) => this.setState({phone_number: event.target.value})}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default BlacklistedForm
