import React, { Component } from 'react';
import { FormControlLabel, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import TradesApi from '../../api/TradesApi';
import ImagesApi from '../../api/ImagesApi';
import Alert from '@material-ui/lab/Alert';

export class SuggestionsForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            suggestion_id : "",
            suggestion : "",
            keywords : "",
            trades_list: [],
            trade: "",
            content: this.props.content,
            error: false
        }
    }

    componentDidMount(){
        this.fetchTrades();
        if(this.state.content){
            this.fromJson(this.state.content)
        }
    }

    fetchTrades = async () => {
        const api = new TradesApi();
        const result = await api.getTrades();
        
        this.setState({
            trades_list: result.trades
        })        
    }

    fromJson = (value) => {
        const jsonObj = JSON.parse(value);
        this.setState({
            suggestion_id : jsonObj["suggestion-id"] === null ? '' : jsonObj["suggestion-id"],
            suggestion : jsonObj["suggestion"] === null ? '' : jsonObj["suggestion"],
            keywords : jsonObj["keywords"] === null ? '' : jsonObj["keywords"],
            trade : jsonObj["trade"] === null ? '' : jsonObj["trade"]
        });
    }

    validate = () => {
        if(this.state.suggestion === '' || this.state.keywords === ''){
            this.setState({error: true});
            return false;
        }
        return true;
    }

    toJson = () => {
        let obj = {
            "suggestion-id": this.state.suggestion_id,
            "suggestion":  this.state.suggestion,
            "keywords":  this.state.keywords,
            "trade": this.state.trade,
        }

        const str =  JSON.stringify(obj);
        return str;
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.error && (<Alert severity="error">One or more required fields are empty!</Alert>)}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        label="Suggestion"
                        variant="outlined"
                        fullWidth
                        value={this.state.suggestion}
                        onChange={(event) => this.setState({suggestion: event.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        label="Keywords"
                        variant="outlined"
                        fullWidth
                        value={this.state.keywords}
                        onChange={(event) => this.setState({keywords: event.target.value})}
                        placeholder="keyword1, keyword2"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-label">Trade</InputLabel>
                        <Select
                            labelId="select-label"
                            value={this.state.trade}
                            onChange={event => this.setState({trade: event.target.value})}
                            label="Trade"
                        >
                            {this.state.trades_list.map((trade, index) => (
                            <MenuItem key={`trade-${index}`} value={trade["trade-name"].toLowerCase()}>
                                {trade["trade-name"]}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}

export default SuggestionsForm
