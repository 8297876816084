import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    background: {
        default: '#fff'
    },
    palette: {
            primary: {
                main: "#51c4c0",
            },
            secondary: {
                main: "#E9B33D"
            }
    },
});

export default theme;