import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Button, IconButton, Dialog, DialogContent, DialogActions, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { v4 as uuid } from 'uuid';
import './Table.css'

class Table extends Component {

  constructor(props){
    super(props);
    this.state = {
      columns : [],
      data : [],
      key : null,
    
      open : false,
      title: "",
      content: "",
      autoId : true,
      mode : "",
    };
    
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    this.listenOnEventBus();
  }
  
  listenOnEventBus() {
    this.props.eventemitter.on("export-table", this.exportTable);
    this.props.eventemitter.on("filter-selected", (payload) => {
      this.setState({
        ...this.state,
        columns: payload.filter.cols
      })
    });
    this.props.eventemitter.on("table-data", (payload) => {
      this.setState({
        ...this.state,
        data : payload.data,
        key : payload.key,
      })
    });
    this.props.eventemitter.on("table-append-data", (payload) => {
      this.setState({
        ...this.state,
        data : this.state.data.concat(payload.data),
        key : payload.key
      })
    });
    
  }

  handleLoadMore = (event) => {
    this.props.eventemitter.emit("table-load-more", {
        key : this.state.key
      })
  }

  handleSearch = (event) => {
    this.searchTable(event.target.value);
  }

  searchTable = (searchText) => {        
    var table = document.getElementById(`filterable-table-'${this.props.tabId}'`);
    var tr = table.querySelectorAll("tbody>tr");

    for (var i = 0; i < tr.length; i++) {
      var found = false;
      var td = tr[i].getElementsByTagName("td");
      for (var j = 0; j < td.length; j++){
          if(searchText.length === 0){
            found = true;
            break;
          }
          var val = td[j].textContent || td[j].innerText;
          if (val.toUpperCase().indexOf(searchText.toUpperCase()) > -1) {
              found = true;
          }
      }
      tr[i].style.display = found ? "" : "none"; 
    }
  }

  exportTable = async () => {
    import(/* XLSX */ 'xlsx')
        .then(response => response.default)
        .then(XLSX => {                
            const ws = XLSX.utils.table_to_sheet(document.getElementById(`filterable-table-'${this.props.tabId}'`));
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet");
            /* generate XLSX file and send to client */
            XLSX.writeFile(wb, "GoTradie-Admin.xlsx")

        }).catch(err => {
            console.log('Unable to export the table. Error:', err);
        })
  }

  handleView = (data) => {
    this.setState({
      ...this.state,
      open: true,
      title: "Details",
      content: JSON.stringify(data, undefined, 4),
      mode: "V"
    });
  }

  handleCreate = () => {
    this.setState({
      ...this.state,
      open: true,
      title: "Add New",
      mode: "C",
      content: undefined
    }, () => {
      if(this.props.template){
        fetch(this.props.template)
        .then(response => response.json())
        .then(data => {
            this.setState({
                ...this.state,
                content : JSON.stringify(data, undefined, 4)
            });
        }).catch(err => {
            console.log('Unable to load template file...', err);
        });
      }
      
    });
  }

  handleEdit = (data) => {
    this.setState({
      ...this.state,
      open: true,
      title: "Update",
      content: JSON.stringify(data, undefined, 4),
      mode: "E"
    });
  }

  handleDelete = (data) => {
    this.setState({
      ...this.state,
      mode: "D"
    });

    this.props.eventemitter.emit("table-delete-item", {
      item : data
    });
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false
    });
  }

  handleSave = () => {
    if (!this.myRef.current.validate()) {
      return;
    }

    let content = this.props.template ? this.state.content : this.myRef.current.toJson();
    
    if(this.state.mode === "C" && this.state.autoId && this.props.idproperty){
      let obj = JSON.parse(content);
      obj[this.props.idproperty] = uuid();
      content = JSON.stringify(obj);
    }
    this.props.eventemitter.emit("table-save-item", {
      item : content
    });
    this.setState({
      ...this.state,
      open: false,
      autoId: false
    });
  }

  renderColumnData(item, row, label) {
    if (item !== null) {
      if (typeof item === 'object') {
        // services return by object
        let services = []
        if (item !== {}) {
          const keys = Object.keys(item);
          keys.forEach((key, index) => {
            let obj = {
              "service-id": key,
              "name": item[key].name,
              "trade-hash": row["trade-hash"]
            }
            services.push(obj)
          });
        }
        return (
          <div class="selective-row">
            {item !== {} && <div>
              {services.map((el, index) => (
                <div class="selective-col">
                  <div style={{background: '#51c4c0', minHeight: 30, borderRadius: 4, width: 120}}>
                    <p style={{textAlign: 'center', margin: 8}}>{el.name.toString()}</p>
                  </div>
                  <IconButton onClick={() => this.handleDelete(el)} title="Delete">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
            </div>}
            <div>
              <IconButton onClick={() => this.handleEdit(row)} title="Add New">
                <AddCircleIcon />
              </IconButton>
            </div>
          </div>
        )
      } else {
        return (
          item.toString()
        )
      }
    } else {
      if (label === "Services") {
        return (
          <div>
            <IconButton onClick={() => this.handleEdit(row)} title="Add New">
              <AddCircleIcon />
            </IconButton>
          </div>
        )
      } else {
        return (
          '-'
        )
      }
    }
  }

  render(){
    let Component = this.props.updateComponent;
    return (
      <div>
        {/* Table Controlls */}
        <div style={{textAlign:"right"}}>
          {this.state.data.length > 0 && (<IconButton className="icon-button" onClick={this.exportTable} title="Export">
            <CloudDownloadIcon />
          </IconButton>)}
          {this.props.creatable && (
            <IconButton className="icon-button" onClick={this.handleCreate} title="Add New">
              <AddCircleIcon />
            </IconButton>
          )}
          <TextField 
          className="search-input"
          label="Search" variant="outlined" size="small" 
          InputProps={{
            endAdornment: <InputAdornment position="end"><SearchIcon style={{opacity:'0.5'}} /></InputAdornment>,
          }}
          onChange={this.handleSearch}/>
        </div>
        {/* Data Table */}
        <table id={`filterable-table-'${this.props.tabId}'`} className="admin-table">
          <thead>
            { this.state.columns.length > 0 && (
                <tr>
                  {this.state.columns.map(col => (
                    <td key={col['name']}>{col['label']}</td>
                  ))}
                  {(this.props.viewable || this.props.editable || this.props.deletable) && (
                    <td></td>
                  )}
                </tr>
              ) }
          </thead>
          <tbody>
            { this.state.data && this.state.data.map((row, index) => (
              <tr key={index}>
                  {this.state.columns.map((col, colIndex) => (
                    <td key={index + "-" + colIndex}>{this.renderColumnData(row[col['name']], row, col['label'])}</td>
                  ))}
                  {(this.props.viewable ||this.props.editable || this.props.deletable) && (
                    <td>
                      {this.props.viewable && (<IconButton onClick={() => this.handleView(row)} title="View Details">
                        <VisibilityIcon fontSize="small" />
                      </IconButton>)}
                      {this.props.editable && (
                      <IconButton onClick={() => this.handleEdit(row)} title="Update">
                        <EditIcon fontSize="small" />
                      </IconButton>)}
                      {this.props.deletable && (
                      <IconButton onClick={() => this.handleDelete(row)} title="Delete">
                        <DeleteIcon fontSize="small" />
                      </IconButton>)}
                    </td>
                  )}
              </tr>
            )) }
          </tbody>
        </table>
        {/* Load More Button */}
        { this.state.key && (
          <div style={{width:'100%', textAlign:"center"}}>
            <Button className="more-button" onClick={this.handleLoadMore}>Load More</Button>
          </div>
        )}
        {/* Add / Edit Dialog */}
        <Dialog open={this.state.open} onClose={this.handleClose} fullWidth={true} maxWidth={"md"}>
          <MuiDialogTitle disableTypography>
            <Typography variant="h6">{this.state.title}</Typography>
            <IconButton aria-label="close" className="close-button" onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers>
            { this.props.template ? this._renderTextarea() : <Component ref={this.myRef} content={this.state.content} /> }
          </DialogContent>
          <DialogActions>
            {this.state.mode !== "V" && (
              <Button onClick={this.handleSave} className="overlay-button">Save</Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  _renderTextarea = () => {
    return (<React.Fragment>
      <TextField
        label="Content"
        value={this.state.content}
        variant="outlined"
        multiline
        rows={18}
        style={{width:'100%'}}
        onChange={(event) => this.setState({...this.state, content: event.target.value})}
      />
      {this.state.mode ==="C" && (<FormControlLabel
        control={<Checkbox checked={this.state.autoId} onChange={(event) => this.setState({...this.state, autoId: event.target.checked})} color="default" />}
        label={`Replace '${this.props.idproperty}' value with an auto generated UUID before submitting`}
      />)}
    </React.Fragment>);
  }

}


Table.propTypes = {
  eventemitter: PropTypes.object.isRequired,
  viewable: PropTypes.bool,
  creatable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  updateComponent: PropTypes.any,
  template: PropTypes.string,
  idproperty: PropTypes.string,
  tabId: PropTypes.number
}

Table.defaultProps = {
  viewable: false,
  creatable: false,
  editable: false,
  deletable: false,
  updateComponent: undefined,
  template: "",
  idproperty: "",
  tabId: 0
}

export default Table;
