class EventEmitter {

    constructor(){
        this.events = {};
    }

    on = (event, fn) => {
        var callbacks = this.events[event];
        if(callbacks === undefined){
            callbacks = [];  
        }
        this.events[event] = [...callbacks, fn];
    }

    emit = (event, payload) => {
        const callbacks = this.events[event];
        if(callbacks !== undefined){
            callbacks.forEach(callback => {
                callback(payload);
            });
        }
    }
}

export default EventEmitter;