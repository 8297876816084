import { API } from 'aws-amplify';

class TradesApi {
    apiName = 'adminApi';
    path = '/trades';
    initState = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }

    getTrades = () => {
        return new Promise(async (resolve, reject) => {
            
            try {
                const response = await API.get(
                    this.apiName, 
                    this.path, 
                    {
                        ...this.initState
                    });

                if(response.statusCode === 200){
                    resolve(JSON.parse(response.body));
                } else {
                    reject(response.statusCode);
                }
            } catch (error) {
                reject(error)
            }
        });
    }

}

export default TradesApi
