import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import Filter from '../../components/Filter';
import Table from '../../components/Table';
import AdminApi from '../../api/AdminApi';
import EventEmitter from '../../components/EventEmitter';

class Queries extends Component {

    constructor(props){
        super(props);
        this.eventEmitter = new EventEmitter();
        this.state = {
            filter: "",
            params: {},
            page_size: 10,
            key: null
        }
    }

    componentDidMount = () => {
        this.listenOnEventBus();
    }

    listenOnEventBus() {
        this.eventEmitter.on("filter-table", (payload) => {
            this.setState({
                filter : payload.filter,
                params : payload.params,
                page_size : payload.page_size,
                key : null
            }, this.fetchData);
        });
        this.eventEmitter.on("table-load-more", (payload) => {
            this.setState({
                key : payload.key
            }, this.fetchData);
        });  
    }

    fetchData = async () => {
        const api = new AdminApi();
        const body = await api.getQuery(
            this.state.filter, 
            this.state.params, 
            this.state.page_size, 
            this.state.key);

        if(this.state.key){
            this.eventEmitter.emit("table-append-data", {
                data : body.entities,
                key : body.start_key
            })
        } else {
            this.eventEmitter.emit("table-data", {
                data : body.entities,
                key : body.start_key,
            })
        }
    }

    render() {
        return (
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="title">Queries</div>
                    </Grid>
                    <Grid item xs={4}>
                        <Filter eventemitter={this.eventEmitter} category="queries" />
                    </Grid>
                    <Grid item xs={8}>
                        <Table 
                            eventemitter={this.eventEmitter}
                            viewable={true}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default Queries