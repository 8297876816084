import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <footer className="footer">
            © 2022 by GoTradie™
        </footer>
    )
}
