import React, { Component } from 'react';
import { FormControlLabel, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import TradesApi from '../../api/TradesApi';
import Alert from '@material-ui/lab/Alert';

export class TradeListForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            trade_hash : "",
            trade_name : "",
            need_license : false,
            license_types: [],
            trades_list: [],
            associated_trades: [],
            content: this.props.content,
            error: false
        }
    }

    componentDidMount(){
        this.fetchTrades();
        if(this.state.content){
            this.fromJson(this.state.content)
        }
    }

    fetchTrades = async () => {
        const api = new TradesApi();
        const result = await api.getTrades();
        
        this.setState({
            trades_list: result.trades
        })        
    }

    fromJson = (value) => {
        const jsonObj = JSON.parse(value);
        this.setState({
            trade_hash : jsonObj["trade-hash"] === null ? '' : jsonObj["trade-hash"],
            trade_name : jsonObj["trade-name"] === null ? '' : jsonObj["trade-name"],
            need_license : jsonObj["need-license"] === null ? false : jsonObj["need-license"],
            license_types : jsonObj["license-types"] === null ? [] : jsonObj["license-types"],
            associated_trades : jsonObj["associated-trades"] === null ? [] : jsonObj["associated-trades"],
        })

    }

    validate = () => {
        if(this.state.trade_name === ''){
            this.setState({error: true});
            return false;
        }
        return true;
    }

    toJson = () => {

        let obj = {
            "trade-hash": this.state.trade_hash,
            "trade-name":  this.state.trade_name,
            "need-license":  this.state.need_license,
            "license-types": this.state.license_types,
            "associated-trades": this.state.associated_trades,
        }

        const str =  JSON.stringify(obj);
        return str;
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {this.state.error && (<Alert severity="error">One or more required fields are empty!</Alert>)}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Trade Name"
                        variant="outlined"
                        fullWidth
                        value={this.state.trade_name}
                        onChange={(event) => this.setState({trade_name: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={this.state.need_license}
                            onChange={event => this.setState({
                                need_license: event.target.checked,
                                license_types: event.target.checked ? this.state.license_types : [],
                                associated_trades: event.target.checked ? this.state.associated_trades : [],
                            })}
                            color="primary"
                        />
                        }
                        label="Need License"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-label-license-types">License Types</InputLabel>
                        <Select
                            labelId="select-label-license-types"
                            value={this.state.license_types}
                            onChange={event => this.setState({license_types: event.target.value})}
                            label="License Types"
                            multiple
                            disabled={!this.state.need_license}
                        >
                            <MenuItem key="license-1" value="nsw-vocational">NSW Vocational</MenuItem>
                            <MenuItem key="license-2" value="solar-installer-license">Solar Installer License</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-label">Associated Trades</InputLabel>
                        <Select
                            labelId="select-label"
                            value={this.state.associated_trades}
                            onChange={event => this.setState({associated_trades: event.target.value})}
                            label="Associated Trades"
                            multiple
                            disabled={!this.state.need_license}
                        >
                            {this.state.trades_list.map((trade, index) => (
                            <MenuItem key={`trade-${index}`} value={trade["trade-name"].toLowerCase()}>
                                {trade["trade-name"]}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}

export default TradeListForm
