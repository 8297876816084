import React, { Component } from 'react'
import AdminApi from '../../api/AdminApi';
import EventEmitter from '../../components/EventEmitter';
import {Button, ButtonGroup} from '@material-ui/core';

class Users extends Component {

    constructor(props){
        super(props);
        this.eventEmitter = new EventEmitter();
        this.state = {
            filter: "resetSearchIndexes",
            params: {"user-profiles" : true },
            page_size: 10,
            start_key: 0
        }
    }

    reindexUsers = async () => {
        const api = new AdminApi();
        const body = await api.reindexingQuery(
            this.state.filter, 
            this.state.params, 
            this.state.page_size, 
            this.state.start_key);
    }

    render() {
        return (
            <div className="container">
                <ButtonGroup variant="outlined">
                    <Button className="reindex-button" onClick={this.reindexUsers}>Reindex Users</Button>
                </ButtonGroup>
            </div>
        )
    }
}

export default Users